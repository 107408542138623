import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

const ThanksStyles = styled.div`
  @mixin break($p, $b: 'max-width') {
    @media screen and ($b: $p + px) {
      @content;
    }
  }
  #thank-you {
    // height: 100vh;
    position: relative;
    display: block;
    padding: 40px 0 120px;
    background-color: var(--white);
    h2 {
      text-align: center;
      @media screen and (max-width: 650px) {
        font-size: 38px;
      }
    }
    p {
      text-align: center;
      max-width: 900px;
      margin: 0 auto;
      &:last-of-type {
        margin-bottom: 25px;
      }
      @media screen and (max-width: 650px) {
        font-size: 18px;
      }
    }
    @media screen and (max-width: 768px) {
      display: block;
    }
    @media screen and (max-width: 650px) {
      padding: 30px 0 70px;
    }
    a {
      font-size: 2rem;
      border-bottom: 1px solid #474747;
      @media screen and (max-width: 650px) {
        font-size: 18px;
      }
    }
    .to-home {
      text-align: center;
      display: block;
      padding: 20px 0 0;
      a.btn {
        font-size: 2.2rem;
        color: white;
        border: 0;
        padding: 15px 20px;
        background: var(--gold);
        @media screen and (max-width: 650px) {
          /* font-size: 18px; */
        }
      }
    }
  }
`;

export default function PrivacyPage() {
  return (
    <>
      <SEO title="SGM | Thank you!" />
      <ThanksStyles>
        <section id="thank-you">
          <div className="wrap">
            <h2>Thank you for your purchase</h2>
            <p>A receipt will be emailed to you shortly.</p>
            <p>Please allow 4-6 weeks for order fulfillment.</p>
            <p>
              If you have any questions, please email{' '}
              {/* Inserts a JSX space */}
              <a href="mailto:hello@stephengerbermusic.com" target="_blank">
                hello@stephengerbermusic.com
              </a>
              .
            </p>
            <div className="to-home">
              <Link className="btn" to="/">
                Return Home
              </Link>
            </div>
          </div>
        </section>
      </ThanksStyles>
      <Footer />
    </>
  );
}
